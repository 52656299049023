import React from 'react';
import { AppBar} from './components/AppBar';
import Footer from './components/Footer'
import MetaTags from './components/MetaTags';
import { Container,Row,Col } from 'react-bootstrap';
import { FaRegFaceSmile } from "react-icons/fa6";
import { PiSmileySadBold } from "react-icons/pi";
const SafetyTipsOne = () => {
  return (
    <div>
      <MetaTags
        title="Safety Tips - Sivakasi Crackers - Whole sale Crackers"
        type="website"
        siteName="diwalicracker.com"
        url="https://diwalicracker.com/safetytips"
        keywords="Safety Tips | Sivakasi Crackers | Fireworks Safety"
        description="Learn essential safety tips for handling fireworks Sivakasi Crackers."
        revisitAfter="10 Days"
      />
      <AppBar />
      <img src={require('../assets/images/banner_two.jpg')} className='img-fluid w-100' alt='product name' />
      <>
        <Container className='padding'>
            <Row>
              <Col lg='12' className='py-3'>
                <h1 className='text-center bold'>Safety Tips</h1>
              </Col>
              <Col lg='12' className='py-3'>
                <h1 className='text-center bold'>Sivakasi Crackers</h1>
              </Col>

              <Col lg='12' className='py-3'>
                <div className='text-center py-3'>
                  <div className='title shadow bg-green'> Do's </div>
                </div>
              </Col>
              <Col lg="4">
                <div className='safe-list box-green'>
                  <div className='safe-icon'>
                    <FaRegFaceSmile />
                  </div>
                  <div className='safe-desc ms-3'>
                    <div>Display fireworks as per the warnings and instructions mentioned on the pack.</div>
                  </div>
                </div>
              </Col>
              <Col lg="4">
                <div className='safe-list box-green'>
                  <div className='safe-icon'>
                    <FaRegFaceSmile />
                  </div>
                  <div className='safe-desc ms-3'>
                    <div>Buy fireworks directly from Manufacturer or from authorized dealer only.</div>
                  </div>
                </div>
              </Col>
              <Col lg="4">
                <div className='safe-list box-green'>
                  <div className='safe-icon'>
                    <FaRegFaceSmile />
                  </div>
                  <div className='safe-desc ms-3'>
                   
                    <div>Always wear eye protection when lightening fireworks.</div>
                  </div>
                </div>
              </Col>
              <Col lg="4">
                <div className='safe-list box-green'>
                  <div className='safe-icon'>
                    <FaRegFaceSmile />
                  </div>
                  <div className='safe-desc ms-3'>
                    <div>
                    Use an agarbatti to ignite the fireworks.</div>
                  </div>
                </div>
              </Col>
              <Col lg="4">
                <div className='safe-list box-green'>
                  <div className='safe-icon'>
                    <FaRegFaceSmile />
                  </div>
                  <div className='safe-desc ms-3'>
                    <div>Store fireworks in a cool and dry place.</div>
                  </div>
                </div>
              </Col>
              <Col lg="4">
                <div className='safe-list box-green'>
                  <div className='safe-icon'>
                    <FaRegFaceSmile />
                  </div>
                  <div className='safe-desc ms-3'>
                    <div>Always have adult supervision.</div>
                  </div>
                </div>
              </Col>
              <Col lg='12' className='py-3'>
                <div className='text-center py-3'>
                  <div className='title shadow bg-red'> Dont 's </div>
                </div>
                </Col>
                <Col lg='4'>
                  <div className='safe-list box-red'>
                    <div className='safe-icon'>
                      <PiSmileySadBold />
                    </div>
                    <div className='safe-desc ms-3'>
                      <div>Display fireworks as per the instructions mentioned on the pack.</div>
                    </div>
                  </div>
                </Col>
                <Col lg='4'>
                  <div className='safe-list box-red'>
                    <div className='safe-icon'>
                    <PiSmileySadBold />
                    </div>
                    <div className='safe-desc ms-3'>
                      <div>Don’t store firecrackers near burning candles or lamps.</div>
                    </div>
                  </div>
                </Col>
                <Col lg='4'>
                  <div className='safe-list box-red'>
                    <div className='safe-icon'>
                    <PiSmileySadBold />
                    </div>
                    <div className='safe-desc ms-3'>
                      <div>Do not wear loose clothing while using fireworks.</div>
                    </div>
                  </div>
                </Col>
                <Col lg='4'>
                  <div className='safe-list box-red'>
                    <div className='safe-icon'>
                    <PiSmileySadBold />
                    </div>
                    <div className='safe-desc ms-3'>
                      <div>Never point or throw fireworks at another person.</div>
                    </div>
                  </div>
                </Col>
                <Col lg='4'>
                  <div className='safe-list box-red'>
                    <div className='safe-icon'>
                    <PiSmileySadBold />
                    </div>
                    <div className='safe-desc ms-3'>
                      <div>Alcohol causes delayed body responses & crackers might burst early.</div>
                    </div>
                  </div>
                </Col>
                <Col lg='4'>
                  <div className='safe-list box-red'>
                    <div className='safe-icon'>
                    <PiSmileySadBold />
                    </div>
                    <div className='safe-desc ms-3'>
                      <div>After fireworks display never pick up fireworks that may be left over, they may still active.</div>
                    </div>
                  </div>
                </Col>
            </Row>
        </Container>
      </>
      <Footer/>
    </div>
  );
};



const SafetyTipsTwo = () => {
  return (
    <>
      <MetaTags
        title="Safety Tips - Sivakasi Crackers - Whole sale Crackers"
        type="website"
        siteName="diwalicracker.com"
        url="https://diwalicracker.com/safetytips"
        keywords="Safety Tips | Sivakasi Crackers | Fireworks Safety"
        description="Learn essential safety tips for handling fireworks Sivakasi Crackers."
        revisitAfter="10 Days"
      />
      <AppBar />
      <img src={require('../assets/images/banner/banner_three.jpg')} className='img-fluid w-100' alt='product name' />
      <div className='padding'>
          <Container>
            <Row>
            <Col lg="12">
              <h2 className='bold text-center my-3'>Do ' s</h2>
            </Col>
              <Col lg='4' md="6" xs="12">
                <div className='text-lg-end text-left my-3'>
                  <div className='sft-icon my-3'>
                    <FaRegFaceSmile size={26} />
                  </div>
                  <div>
                    <h4 className='bold'>Instructions</h4>
                    <p>Display fireworks as per the instructions mentioned on the pack.</p>
                  </div>
                </div>
                <div className='text-lg-end text-left my-3'>
                  <div className='sft-icon my-3'>
                    <FaRegFaceSmile size={26} />
                  </div>
                  <div>
                    <h4 className='bold'>Branded Fireworks</h4>
                    <p>Buy fireworks from authorized / reputed manufacturers only.</p>
                  </div>
                </div>
              </Col>
              <Col lg="4"  md="6" xs="12">
                <div className='my-3 d-lg-block d-none'>
                  <img src={require('../assets/images/safetytips.png')} className='img-fluid w-100' alt='product name' />
                </div>
                <div className='my-3 text-lg-center text-left'>
                  <div className='sft-icon my-3'>
                    <FaRegFaceSmile size={26} />
                  </div>
                  <div>
                    <h4 className='bold'>Outdoor</h4>
                    <p>Use fireworks only outdoor.</p>
                  </div>
                </div>
              </Col>
              <Col lg='4' md="6" xs="12">
                <div className='my-3'>
                  <div className='sft-icon my-3'>
                    <FaRegFaceSmile size={26} />
                  </div>
                  <div>
                    <h4 className='bold'>Distance</h4>
                    <p>Light only one firework at a time, by one person. Others should watch from a safe distance.</p>
                  </div>
                </div>
                <div className='my-3'>
                  <div className='sft-icon my-3'>
                    <FaRegFaceSmile size={26} />
                  </div>
                  <div>
                    <h4 className='bold'>Water</h4>
                    <p>Keep two buckets of water handy. In the event of fire or any mishap.</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
      </div>
      <div className='padding'>
          <Container>
            <Row>
            <Col lg="12">
              <h2 className='bold text-center my-3'>Dont ' s</h2>
            </Col>
              <Col lg='4' md="6" xs="12">
                <div className='text-lg-end text-left my-3'>
                  <div className='sft-icon red my-3'>
                    <PiSmileySadBold  size={26}/>
                  </div>
                  <div>
                    <h4 className='bold'>Don't make tricks</h4>
                    <p>Never make your own fireworks.</p>
                  </div>
                </div>
                <div className='text-lg-end text-left my-3'>
                  <div className='sft-icon red my-3'>
                    <PiSmileySadBold  size={26}/>
                  </div>
                  <div>
                    <h4 className='bold'>Don't relight</h4>
                    <p>Never try to re-light or pick up fireworks that have not ignited fully.</p>
                  </div>
                </div>
              </Col>
              <Col lg="4"  md="6" xs="12">
                <div className='my-3 d-lg-block d-none'>
                  <img src={require('../assets/images/safetytips.png')} className='img-fluid w-100' alt='product name' />
                </div>
                <div className='my-3 text-lg-center text-left'>
                  <div className='sft-icon red my-3'>
                    <PiSmileySadBold  size={26}/>
                  </div>
                  <div>
                    <h4 className='bold'>Don't wear loose clothes</h4>
                    <p>Do not wear loose clothing while using fireworks.</p>
                  </div>
                </div>
              </Col>
              <Col lg='4' md="6" xs="12">
                <div className='my-3'>
                  <div className='sft-icon red my-3'>
                    <PiSmileySadBold  size={26}/>
                  </div>
                  <div>
                    <h4 className='bold'>Don't Touch it</h4>
                    <p>After fireworks display never pick up fireworks that may be left over, they still may be active.</p>
                  </div>
                </div>
                <div className='my-3'>
                  <div className='sft-icon red my-3'>
                    <PiSmileySadBold  size={26}/>
                  </div>
                  <div>
                    <h4 className='bold'>Don't carry it</h4>
                    <p>Never carry fireworks in your pockets.</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
      </div>

      <Footer/>
    </>
  )
}
export  {SafetyTipsOne,SafetyTipsTwo};




